/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('manage', {
      parent: 'root',
      redirectTo: '.account',
      url: '/manage',
      templateUrl: 'apps/manage/templates/acp-manage.ng.html',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.manage" */ 'apps/manage').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('manage.account', {
      url: '/profile',
      template: '<ui-view/>'
    })
    .state('manage.subaccounts', {
      url: '/subaccounts',
      template: '<ui-view/>'
    })
    .state('manage.subaccounts.target', {
      url: '/subaccounts/:id/',
      template: '<ui-view/>'
    })
    .state('manage.contacts', {
      url: '/contacts',
      template: '<ui-view/>'
    })
    .state('manage.notification-preferences', {
      url: '/notification-preferences',
      template: '<ui-view/>'
    });
}

export default states;
